import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { ConicBackground } from '@/components/Backgrounds/Conic';
import clsx from 'clsx';
import { Arrow } from '@/images/svg/Arrow';
import { TConicGradientClasses } from '@/interfaces/layout';

interface IProps extends PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
  testId?: string;
  type?: 'submit' | 'reset' | 'button';
  buttonClassname?: string;
  buttonTextClassname?: string;
  arrowVariant?: 'primary' | 'secondary' | 'green';
  disabled?: boolean;
  gradient?: TConicGradientClasses;
  withArrow?: boolean;
  gradientPosition?: string;
  buttonWrapperClassname?: string;
}

export const ArrowButton = ({
  testId,
  type = 'submit',
  children,
  disabled,
  gradient = 'bg-conic-gradient-light',
  buttonClassname,
  buttonTextClassname,
  arrowVariant = 'primary',
  withArrow = true,
  gradientPosition,
  buttonWrapperClassname,
  ...rest
}: IProps) => {
  return (
    <button
      data-testid={testId}
      type={type}
      className={clsx(
        `flex group items-center justify-center 
        relative overflow-hidden rounded-full 
        px-base py-[13.5px] border-[0.5px] min-w-[111px] isolate`,
        {
          [`${buttonClassname}`]: buttonClassname,
        },
      )}
      disabled={disabled}
      {...rest}
    >
      <span
        className={clsx(`${buttonTextClassname} block`, {
          [`${buttonWrapperClassname}`]: buttonWrapperClassname,
        })}
      >
        {children}
        {withArrow && <Arrow variant={arrowVariant} />}
      </span>

      <ConicBackground
        gradient={gradient}
        classname={clsx('w-[310%] h-[1000%]', { [`${gradientPosition}`]: gradientPosition })}
      />
    </button>
  );
};
